<template>
  <card-item legend="Campanhas de Marketing" icon="megaphone" icon-type="fa">
    <template v-slot:card-icon>
      <i class="fa fa-duotone fa-megaphone" />
    </template>
    <div class="card-content">
      Todos os serviços estão normais.
    </div>
    <template v-slot:card-footer>
      <div class="card-footer-center">
        <a @click="$router.push('leiloes/monitoramento')" class="card-btn-center">
          Monitorar Serviços
        </a>
      </div>
<!--      <div class="diviser"></div>
      <a class="card-btn">
        <u-icon name="arrow_forward"/>
        Ir para os avisos do software
      </a>-->
    </template>
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'

export default {
  name: "CampanhasMkt",
  components: {CardItem}
}
</script>
