<template>
  <card-item ch-legend="TAREFAS ATIVAS" :loading="dashboard.loading" legend="Tarefas" icon="tasks" icon-type="fa" :link="() => $router.push('/sl/tarefas')">
    <div class="card-link-column">
      <card-item-link @click.native="$router.push({name: 'sl.tarefas.lista'})" icon="calendar-exclamation" legend="Suas tarefas pendentes" :subtitle="stats.usuarioAtivas"
                      icon-type="fa">
        <template v-slot:card-icon>
          <i class="fa fa-duotone fa-alarm-clock" />
        </template>
      </card-item-link>
      <card-item-link @click.native="$router.push({name: 'sl.tarefas.lista'})" icon="calendar-exclamation" legend="Tarefas em andamento" :subtitle="stats.usuarioTotalAndamento"
                      icon-type="fa" icon-style="duotone">
        <template v-slot:card-icon>
          <i class="fa fa-duotone fa-calendar-exclamation" />
        </template>
      </card-item-link>
    </div>
    <div class="card-link-column">
      <card-item-link @click.native="$router.push({name: 'sl.tarefas.lista'})" icon="calendar-check" legend="Tarefas vencendo hoje" :subtitle="stats.usuarioVencendoHoje"
                      icon-type="fa"/>
      <card-item-link @click.native="$router.push({name: 'sl.tarefas.lista'})" icon="calendar" legend="Tarefas realizadas hoje" :subtitle="stats.usuarioRealizadasHoje" icon-type="fa"/>
    </div>
    <div class="card-link-column">
      <card-item-link @click.native="$router.push({name: 'sl.tarefas.lista'})" icon="calendar-check" legend="Tarefas para a semana" :subtitle="stats.usuarioTotalSemana"
                      icon-type="fa"/>
      <card-item-link @click.native="$router.push({name: 'sl.tarefas.lista'})" icon="calendar" legend="Total tarefas vencidas" :subtitle="stats.usuarioTotalVencidas" icon-type="fa"/>
    </div>
    <template v-slot:card-footer>
    <div class="diviser"></div>
      <a @click="novaTarefa" class="card-btn link">
        <u-icon name=""/>
        ADICIONAR UMA NOVA TAREFA
      </a>
  <!--    <div class="diviser"></div>
      <a @click="$router.push({name: 'sl.tarefas.lista'})" class="card-btn">
        <u-icon name="arrow_forward"/>
        Ir para o módulo de tarefas
      </a>-->
    </template>
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import CardItemLink from '@/reuse/card/CardItemLink'
import Mixin from './mixin'
import windowTarefa from "components/sltarefas/windows/tarefa"

let mock = {
  resource: null,
  usuarioAtivas: 0, //
  usuarioRealizadasHoje: 0, //
  usuarioTotalAndamento: 0, //
  usuarioTotalSemana: 0,
  usuarioTotalVencidas: 0,
  usuarioVencendoHoje: 0 //
}

export default {
  name: 'TarefasStats',
  mixins: [Mixin],
  components: {CardItemLink, CardItem},
  computed: {
    stats() {
      if (!this.basicData || !this.basicData.tarefas) {
        return mock
      }
      return this.basicData.tarefas.status
    }
  },
  methods: {
    windowTarefa: windowTarefa,
    novaTarefa () {
      this.windowTarefa()
    }
  }
}
</script>
